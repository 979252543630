<template>
  <div
    v-show="loading"
    class="w-full h-full fixed block top-0 left-0 bg-white opacity-75 z-50"
  >
    <span
      class="opacity-75 top-1/2 my-0 mx-auto block relative w-0 h-0"
      style="top: 50%"
    >
      <svg
        class="animate-spin ml-3 h-5 w-5"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          class="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          stroke-width="4"
        />
        <path
          class="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        />
      </svg>
    </span>
  </div>

  <div
    v-show="!loading"
    class="checkout-wrapper"
    :class="{ 'locker-selection': transaction.method == 'elockers' }"
  >
    <div
      v-if="transaction.method == 'elockers'"
      class="step-counter radius-anchor last-step"
    >
      <div
        class="radius-anchor step-counter-inner"
        :style="'width: ' + (parseInt(step_counter) / parseInt(applicationLockerFlowTotalSteps)) * 100 + '%;'"
      />
    </div>
    <Form
      ref="form"
      v-slot="{ errors, setFieldValue }"
      onkeydown="return event.key != 'Enter';"
      :validation-schema="validationRules"
      @submit="onSubmit()"
      @invalid-submit="scrollToTop()"
    >
      <!-- {{ errors }} -->
      <div class="mb-5">
        <router-link
          v-if="step == 'personalInfoStep'"
          :to="{ name: basket_link, params: { location_slug: location.slug }, query: $route.query }"
          class="cursor-pointer inline-flex justify-start"
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9.70711 16.7071C9.31658 17.0976 8.68342 17.0976 8.29289 16.7071L2.29289 10.7071C1.90237 10.3166 1.90237 9.68342 2.29289 9.29289L8.29289 3.29289C8.68342 2.90237 9.31658 2.90237 9.70711 3.29289C10.0976 3.68342 10.0976 4.31658 9.70711 4.70711L5.41421 9H17C17.5523 9 18 9.44772 18 10C18 10.5523 17.5523 11 17 11L5.41421 11L9.70711 15.2929C10.0976 15.6834 10.0976 16.3166 9.70711 16.7071Z"
              fill="currentColor"
            />
          </svg>
          <div class="ml-3">
            {{ $t('shoppingcart.button.back') }}
          </div>
        </router-link>
        <button
          v-else-if="step == 'paymentStep'"
          class="cursor-pointer inline-flex justify-start"
          @click="(direction = 'pages-back'), (setStep('personalInfoStep')), step_counter--"
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9.70711 16.7071C9.31658 17.0976 8.68342 17.0976 8.29289 16.7071L2.29289 10.7071C1.90237 10.3166 1.90237 9.68342 2.29289 9.29289L8.29289 3.29289C8.68342 2.90237 9.31658 2.90237 9.70711 3.29289C10.0976 3.68342 10.0976 4.31658 9.70711 4.70711L5.41421 9H17C17.5523 9 18 9.44772 18 10C18 10.5523 17.5523 11 17 11L5.41421 11L9.70711 15.2929C10.0976 15.6834 10.0976 16.3166 9.70711 16.7071Z"
              fill="currentColor"
            />
          </svg>
          <div class="ml-3">
            {{ $t('shoppingcart.button.back') }}
          </div>
        </button>
      </div>

      <div class="md:flex text-default">
        <div class="md:w-4/6 md:pr-4 relative">
          <!-- error alert -->
          <div
            v-if="error_messages.length > 0"
            class="mb-5 rounded-md bg-red-50 p-4"
          >
            <div class="flex">
              <div class="flex-shrink-0">
                <svg
                  class="h-5 w-5 text-red-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <div
                v-for="(error, index) in error_messages"
                :key="index"
                class="ml-3"
              >
                <h3 class="text-sm font-medium text-red-800">
                  {{ error.message }}
                </h3>
                <div
                  v-if="error.unavailable_items"
                  class="mt-2 text-sm text-red-700"
                >
                  <ul class="list-disc pl-5 space-y-1">
                    <li
                      v-for="(item, index_item) in error.unavailable_items"
                      :key="index_item"
                    >
                      {{ item }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <transition-group tag="div">
            <div
              v-show="step == 'personalInfoStep'"
              class="first-step-checkout"
            >
              <!-- tableservice -->
              <div
                v-if="form.method == 'tableservice'"
                class="content-container checkout-box radius-anchor mb-5"
              >
                <h2 class="text-lg leading-6 text-2xl font-bold font-medium">
                  {{ $t('order.table.title') }}
                  <span class="mt-2 leading-6 font-bold text-default">{{ table.attributes.name }}</span>
                </h2>
              </div>

              <!-- your details -->
              <div
                v-if="['delivery', 'takeaway', 'quickorder', 'elockers', 'tableservice', 'reservation'].includes(form.method)"
                class="content-container personal-info radius-anchor"
              >
                <div class="pb-2 mb-3">
                  <div class="-ml-2 -mt-2 flex flex-wrap items-baseline">
                    <h2 class="ml-2 mt-2 text-2xl font-bold leading-6">
                      {{ $t('order.personalinformation') }}
                    </h2>
                  </div>
                </div>
                <div
                  v-if="['delivery', 'takeaway'].includes(form.method) && !emailOnlyForm"
                  class="sm:grid sm:grid-cols-2 gap-3"
                >
                  <!-- firstname -->
                  <div class="sm:col-span-1 mb-3">
                    <label
                      for="customer_firstname"
                      class="block text-xs"
                      :class="{ 'text-red-500': errors.customer_firstname && formDirty }"
                    >{{ $t('fields.firstname') }} *</label>
                    <div class="mt-1">
                      <Field
                        v-model="form.customer_firstname"
                        type="text"
                        name="customer_firstname"
                        class="py-1 px-2 block w-full shadow-sm border rounded-sm focus:outline-none focus:ring-transparent"
                        :class="{ error: errors.customer_firstname }"
                        @change="validateForm()"
                      />
                    </div>
                  </div>
                  <!-- lastname -->
                  <div class="sm:col-span-1 mb-3">
                    <label
                      for="customer_lastname"
                      class="block text-xs"
                      :class="{ 'text-red-500': errors.customer_lastname && formDirty }"
                    >{{ $t('fields.lastname') }} *</label>
                    <div class="mt-1">
                      <Field
                        v-model="form.customer_lastname"
                        type="text"
                        name="customer_lastname"
                        class="py-1 px-2 block w-full shadow-sm border rounded-sm focus:outline-none focus:ring-transparent"
                        :class="{ error: errors.customer_lastname }"
                        @change="validateForm()"
                      />
                    </div>
                  </div>
                </div>

                <div
                  v-if="accounts.length > 0"
                  class="mb-3"
                >
                  <label
                    for="account_id"
                    class="block text-xs"
                    :class="{ 'text-red-500': errors.account_id && formDirty }"
                  >{{
                    $t('fields.account')
                  }}</label>
                  <div class="mt-1">
                    <Field
                      v-model="form.account_id"
                      as="select"
                      name="account_id"
                      class="py-1 px-2 block w-full shadow-sm border rounded-sm focus:outline-none focus:ring-transparent"
                      :class="{ error: errors.account_id }"
                      @change="
                        accountIdUpdated();
                        validateForm();
                      "
                    >
                      <option />
                      <option
                        v-for="(account, index) in accounts"
                        :key="index"
                        :value="account.id"
                        :selected="form.account_id == account.id"
                      >
                        {{ account.attributes.name }}
                      </option>
                    </Field>
                  </div>
                </div>

                <div
                  v-if="['delivery', 'takeaway', 'quickorder', 'elockers', 'tableservice', 'reservation'].includes(form.method)"
                  class="mb-3"
                >
                  <label
                    for="customer_email"
                    class="block text-xs"
                    :class="{ 'text-red-500': errors.customer_email && formDirty }"
                  >
                    {{ $t('fields.email') }}
                    <span
                      v-if="
                        (form.method === 'quickorder' && location.quickorder.email_required == true) ||
                          ['delivery', 'takeaway', 'tableservice', 'elockers'].includes(form.method)
                      "
                    >*</span>
                  </label>
                  <div class="mt-1">
                    <Field
                      v-model.trim="form.customer_email"
                      type="text"
                      autocomplete="off"
                      name="customer_email"
                      class="lowercase py-1 px-2 block w-full shadow-sm border rounded-sm focus:outline-none focus:ring-transparent"
                      :class="{ error: errors.customer_email }"
                      @change="validateForm()"
                    />
                  </div>
                </div>

                <div
                  v-if="
                    (['delivery', 'takeaway', 'reservation'].includes(form.method) ||
                      (['elockers'].includes(form.method) && location.lockers.phone_number_required == true) ||
                      (form.method === 'quickorder' && location.quickorder.phone_number_required == true)) &&
                      !emailOnlyForm
                  "
                  class="mb-3 grid grid-cols-4 gap-3"
                >
                  <div class="col-span-1">
                    <label
                      for="customer_phone_number_countrycode"
                      class="block text-xs"
                      :class="{ 'text-red-500': errors.customer_phone_number_countrycode }"
                    >{{ $t('fields.phonenumber_countrycode') }} *</label>
                    <div
                      class="mt-1 relative select-wrapper"
                      :class="{ 'value-selected': form.customer_phone_number_countrycode }"
                    >
                      <svg
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="transparent"
                        stroke="currentColor"
                        class="h-6 w-6 select-icon"
                      >
                        <polyline points="18 15 12 9 6 15" />
                      </svg>

                      <Field
                        v-model="form.customer_phone_number_countrycode"
                        name="customer_phone_number_countrycode"
                        as="select"
                        class="country-select h-10 py-1 px-2 form-select w-full shadow-sm border rounded-sm py-2"
                        :class="{ error: errors.customer_phone_number_countrycode }"
                        @change="validateForm()"
                      >
                        <option />
                        <option
                          data-countryCode="NL"
                          value="NL"
                        >
                          Nederland (+31)
                        </option>
                        <option
                          data-countryCode="BE"
                          value="BE"
                        >
                          België (+32)
                        </option>
                        <option
                          data-countryCode="AF"
                          value="93"
                        >
                          Afghanistan (+93)
                        </option>
                        <option
                          data-countryCode="AL"
                          value="355"
                        >
                          Albania (+355)
                        </option>
                        <option
                          data-countryCode="DZ"
                          value="213"
                        >
                          Algeria (+213)
                        </option>
                        <option
                          data-countryCode="AD"
                          value="376"
                        >
                          Andorra (+376)
                        </option>
                        <option
                          data-countryCode="AO"
                          value="244"
                        >
                          Angola (+244)
                        </option>
                        <option
                          data-countryCode="AI"
                          value="1264"
                        >
                          Anguilla (+1264)
                        </option>
                        <option
                          data-countryCode="AG"
                          value="1268"
                        >
                          Antigua &amp; Barbuda (+1268)
                        </option>
                        <option
                          data-countryCode="AR"
                          value="54"
                        >
                          Argentina (+54)
                        </option>
                        <option
                          data-countryCode="AM"
                          value="374"
                        >
                          Armenia (+374)
                        </option>
                        <option
                          data-countryCode="AW"
                          value="297"
                        >
                          Aruba (+297)
                        </option>
                        <option
                          data-countryCode="AC"
                          value="247"
                        >
                          Ascension Island (+247)
                        </option>
                        <option
                          data-countryCode="AU"
                          value="61"
                        >
                          Australia (+61)
                        </option>
                        <option
                          data-countryCode="AT"
                          value="43"
                        >
                          Austria (+43)
                        </option>
                        <option
                          data-countryCode="AZ"
                          value="994"
                        >
                          Azerbaijan (+994)
                        </option>
                        <option
                          data-countryCode="BS"
                          value="1242"
                        >
                          Bahamas (+1242)
                        </option>
                        <option
                          data-countryCode="BH"
                          value="973"
                        >
                          Bahrain (+973)
                        </option>
                        <option
                          data-countryCode="BD"
                          value="880"
                        >
                          Bangladesh (+880)
                        </option>
                        <option
                          data-countryCode="BB"
                          value="1246"
                        >
                          Barbados (+1246)
                        </option>
                        <option
                          data-countryCode="BY"
                          value="375"
                        >
                          Belarus (+375)
                        </option>
                        <option
                          data-countryCode="BZ"
                          value="501"
                        >
                          Belize (+501)
                        </option>
                        <option
                          data-countryCode="BJ"
                          value="229"
                        >
                          Benin (+229)
                        </option>
                        <option
                          data-countryCode="BM"
                          value="1441"
                        >
                          Bermuda (+1441)
                        </option>
                        <option
                          data-countryCode="BT"
                          value="975"
                        >
                          Bhutan (+975)
                        </option>
                        <option
                          data-countryCode="BO"
                          value="591"
                        >
                          Bolivia (+591)
                        </option>
                        <option
                          data-countryCode="BQ"
                          value="599"
                        >
                          Bonaire, Saba and Sint Eustatius (+599)
                        </option>
                        <option
                          data-countryCode="BA"
                          value="387"
                        >
                          Bosnia Herzegovina (+387)
                        </option>
                        <option
                          data-countryCode="BW"
                          value="267"
                        >
                          Botswana (+267)
                        </option>
                        <option
                          data-countryCode="BR"
                          value="55"
                        >
                          Brazil (+55)
                        </option>
                        <option
                          data-countryCode="IO"
                          value="246"
                        >
                          British Indian Ocean Territory (+246)
                        </option>
                        <option
                          data-countryCode="BN"
                          value="673"
                        >
                          Brunei (+673)
                        </option>
                        <option
                          data-countryCode="BG"
                          value="359"
                        >
                          Bulgaria (+359)
                        </option>
                        <option
                          data-countryCode="BF"
                          value="226"
                        >
                          Burkina Faso (+226)
                        </option>
                        <option
                          data-countryCode="BI"
                          value="257"
                        >
                          Burundi (+257)
                        </option>
                        <option
                          data-countryCode="KH"
                          value="855"
                        >
                          Cambodia (+855)
                        </option>
                        <option
                          data-countryCode="CM"
                          value="237"
                        >
                          Cameroon (+237)
                        </option>
                        <option
                          data-countryCode="CA"
                          value="1"
                        >
                          Canada (+1)
                        </option>
                        <option
                          data-countryCode="CV"
                          value="238"
                        >
                          Cape Verde Islands (+238)
                        </option>
                        <option
                          data-countryCode="KY"
                          value="1345"
                        >
                          Cayman Islands (+1345)
                        </option>
                        <option
                          data-countryCode="CF"
                          value="236"
                        >
                          Central African Republic (+236)
                        </option>
                        <option
                          data-countryCode="TD"
                          value="235"
                        >
                          Chad (+235)
                        </option>
                        <option
                          data-countryCode="CL"
                          value="56"
                        >
                          Chile (+56)
                        </option>
                        <option
                          data-countryCode="CN"
                          value="86"
                        >
                          China (+86)
                        </option>
                        <option
                          data-countryCode="CO"
                          value="57"
                        >
                          Colombia (+57)
                        </option>
                        <option
                          data-countryCode="KM"
                          value="269"
                        >
                          Comoros (+269)
                        </option>
                        <option
                          data-countryCode="CG"
                          value="242"
                        >
                          Congo (+242)
                        </option>
                        <option
                          data-countryCode="CD"
                          value="243"
                        >
                          Congo, Democratic Republic of the (+243)
                        </option>
                        <option
                          data-countryCode="CK"
                          value="682"
                        >
                          Cook Islands (+682)
                        </option>
                        <option
                          data-countryCode="CR"
                          value="506"
                        >
                          Costa Rica (+506)
                        </option>
                        <option
                          data-countryCode="HR"
                          value="385"
                        >
                          Croatia (+385)
                        </option>
                        <option
                          data-countryCode="CU"
                          value="53"
                        >
                          Cuba (+53)
                        </option>
                        <option
                          data-countryCode="CW"
                          value="5999"
                        >
                          Curaçao (+5999)
                        </option>
                        <option
                          data-countryCode="CY"
                          value="357"
                        >
                          Cyprus (+357)
                        </option>
                        <option
                          data-countryCode="CZ"
                          value="420"
                        >
                          Czech Republic (+420)
                        </option>
                        <option
                          data-countryCode="DK"
                          value="45"
                        >
                          Denmark (+45)
                        </option>
                        <option
                          data-countryCode="DJ"
                          value="253"
                        >
                          Djibouti (+253)
                        </option>
                        <option
                          data-countryCode="DM"
                          value="1767"
                        >
                          Dominica (+1767)
                        </option>
                        <option
                          data-countryCode="DO"
                          value="1809"
                        >
                          Dominican Republic (+1809)
                        </option>
                        <option
                          data-countryCode="TL"
                          value="670"
                        >
                          East Timor (+670)
                        </option>
                        <option
                          data-countryCode="EC"
                          value="593"
                        >
                          Ecuador (+593)
                        </option>
                        <option
                          data-countryCode="EG"
                          value="20"
                        >
                          Egypt (+20)
                        </option>
                        <option
                          data-countryCode="SV"
                          value="503"
                        >
                          El Salvador (+503)
                        </option>
                        <option
                          data-countryCode="GQ"
                          value="240"
                        >
                          Equatorial Guinea (+240)
                        </option>
                        <option
                          data-countryCode="ER"
                          value="291"
                        >
                          Eritrea (+291)
                        </option>
                        <option
                          data-countryCode="EE"
                          value="372"
                        >
                          Estonia (+372)
                        </option>
                        <option
                          data-countryCode="SZ"
                          value="268"
                        >
                          Eswatini (+268)
                        </option>
                        <option
                          data-countryCode="ET"
                          value="251"
                        >
                          Ethiopia (+251)
                        </option>
                        <option
                          data-countryCode="FK"
                          value="500"
                        >
                          Falkland Islands (+500)
                        </option>
                        <option
                          data-countryCode="FO"
                          value="298"
                        >
                          Faroe Islands (+298)
                        </option>
                        <option
                          data-countryCode="FJ"
                          value="679"
                        >
                          Fiji (+679)
                        </option>
                        <option
                          data-countryCode="FI"
                          value="358"
                        >
                          Finland (+358)
                        </option>
                        <option
                          data-countryCode="FR"
                          value="33"
                        >
                          France (+33)
                        </option>
                        <option
                          data-countryCode="GF"
                          value="594"
                        >
                          French Guiana (+594)
                        </option>
                        <option
                          data-countryCode="PF"
                          value="689"
                        >
                          French Polynesia (+689)
                        </option>
                        <option
                          data-countryCode="GA"
                          value="241"
                        >
                          Gabon (+241)
                        </option>
                        <option
                          data-countryCode="GM"
                          value="220"
                        >
                          Gambia (+220)
                        </option>
                        <option
                          data-countryCode="GE"
                          value="995"
                        >
                          Georgia (+995)
                        </option>
                        <option
                          data-countryCode="DE"
                          value="49"
                        >
                          Germany (+49)
                        </option>
                        <option
                          data-countryCode="GH"
                          value="233"
                        >
                          Ghana (+233)
                        </option>
                        <option
                          data-countryCode="GI"
                          value="350"
                        >
                          Gibraltar (+350)
                        </option>
                        <option
                          data-countryCode="GR"
                          value="30"
                        >
                          Greece (+30)
                        </option>
                        <option
                          data-countryCode="GL"
                          value="299"
                        >
                          Greenland (+299)
                        </option>
                        <option
                          data-countryCode="GD"
                          value="1473"
                        >
                          Grenada (+1473)
                        </option>
                        <option
                          data-countryCode="GP"
                          value="590"
                        >
                          Guadeloupe (+590)
                        </option>
                        <option
                          data-countryCode="GU"
                          value="1671"
                        >
                          Guam (+1671)
                        </option>
                        <option
                          data-countryCode="GT"
                          value="502"
                        >
                          Guatemala (+502)
                        </option>
                        <option
                          data-countryCode="GN"
                          value="224"
                        >
                          Guinea (+224)
                        </option>
                        <option
                          data-countryCode="GW"
                          value="245"
                        >
                          Guinea - Bissau (+245)
                        </option>
                        <option
                          data-countryCode="GY"
                          value="592"
                        >
                          Guyana (+592)
                        </option>
                        <option
                          data-countryCode="HT"
                          value="509"
                        >
                          Haiti (+509)
                        </option>
                        <option
                          data-countryCode="HN"
                          value="504"
                        >
                          Honduras (+504)
                        </option>
                        <option
                          data-countryCode="HK"
                          value="852"
                        >
                          Hong Kong (+852)
                        </option>
                        <option
                          data-countryCode="HU"
                          value="36"
                        >
                          Hungary (+36)
                        </option>
                        <option
                          data-countryCode="IS"
                          value="354"
                        >
                          Iceland (+354)
                        </option>
                        <option
                          data-countryCode="IN"
                          value="91"
                        >
                          India (+91)
                        </option>
                        <option
                          data-countryCode="ID"
                          value="62"
                        >
                          Indonesia (+62)
                        </option>
                        <option
                          data-countryCode="IR"
                          value="98"
                        >
                          Iran (+98)
                        </option>
                        <option
                          data-countryCode="IQ"
                          value="964"
                        >
                          Iraq (+964)
                        </option>
                        <option
                          data-countryCode="IE"
                          value="353"
                        >
                          Ireland (+353)
                        </option>
                        <option
                          data-countryCode="IL"
                          value="972"
                        >
                          Israel (+972)
                        </option>
                        <option
                          data-countryCode="IT"
                          value="39"
                        >
                          Italy (+39)
                        </option>
                        <option
                          data-countryCode="CI"
                          value="225"
                        >
                          Ivory Coast (+225)
                        </option>
                        <option
                          data-countryCode="JM"
                          value="1876"
                        >
                          Jamaica (+1876)
                        </option>
                        <option
                          data-countryCode="JP"
                          value="81"
                        >
                          Japan (+81)
                        </option>
                        <option
                          data-countryCode="JO"
                          value="962"
                        >
                          Jordan (+962)
                        </option>
                        <option
                          data-countryCode="KZ"
                          value="7"
                        >
                          Kazakhstan (+7)
                        </option>
                        <option
                          data-countryCode="KE"
                          value="254"
                        >
                          Kenya (+254)
                        </option>
                        <option
                          data-countryCode="KI"
                          value="686"
                        >
                          Kiribati (+686)
                        </option>
                        <option
                          data-countryCode="KP"
                          value="850"
                        >
                          Korea, North (+850)
                        </option>
                        <option
                          data-countryCode="KR"
                          value="82"
                        >
                          Korea, South (+82)
                        </option>
                        <option
                          data-countryCode="XK"
                          value="383"
                        >
                          Kosovo (+383)
                        </option>
                        <option
                          data-countryCode="KW"
                          value="965"
                        >
                          Kuwait (+965)
                        </option>
                        <option
                          data-countryCode="KG"
                          value="996"
                        >
                          Kyrgyzstan (+996)
                        </option>
                        <option
                          data-countryCode="LA"
                          value="856"
                        >
                          Laos (+856)
                        </option>
                        <option
                          data-countryCode="LV"
                          value="371"
                        >
                          Latvia (+371)
                        </option>
                        <option
                          data-countryCode="LB"
                          value="961"
                        >
                          Lebanon (+961)
                        </option>
                        <option
                          data-countryCode="LS"
                          value="266"
                        >
                          Lesotho (+266)
                        </option>
                        <option
                          data-countryCode="LR"
                          value="231"
                        >
                          Liberia (+231)
                        </option>
                        <option
                          data-countryCode="LY"
                          value="218"
                        >
                          Libya (+218)
                        </option>
                        <option
                          data-countryCode="LI"
                          value="423"
                        >
                          Liechtenstein (+423)
                        </option>
                        <option
                          data-countryCode="LT"
                          value="370"
                        >
                          Lithuania (+370)
                        </option>
                        <option
                          data-countryCode="LU"
                          value="352"
                        >
                          Luxembourg (+352)
                        </option>
                        <option
                          data-countryCode="MO"
                          value="853"
                        >
                          Macao (+853)
                        </option>
                        <option
                          data-countryCode="MK"
                          value="389"
                        >
                          Macedonia (+389)
                        </option>
                        <option
                          data-countryCode="MG"
                          value="261"
                        >
                          Madagascar (+261)
                        </option>
                        <option
                          data-countryCode="MW"
                          value="265"
                        >
                          Malawi (+265)
                        </option>
                        <option
                          data-countryCode="MY"
                          value="60"
                        >
                          Malaysia (+60)
                        </option>
                        <option
                          data-countryCode="MV"
                          value="960"
                        >
                          Maldives (+960)
                        </option>
                        <option
                          data-countryCode="ML"
                          value="223"
                        >
                          Mali (+223)
                        </option>
                        <option
                          data-countryCode="MT"
                          value="356"
                        >
                          Malta (+356)
                        </option>
                        <option
                          data-countryCode="MH"
                          value="692"
                        >
                          Marshall Islands (+692)
                        </option>
                        <option
                          data-countryCode="MQ"
                          value="596"
                        >
                          Martinique (+596)
                        </option>
                        <option
                          data-countryCode="MR"
                          value="222"
                        >
                          Mauritania (+222)
                        </option>
                        <option
                          data-countryCode="MU"
                          value="230"
                        >
                          Mauritius (+230)
                        </option>
                        <option
                          data-countryCode="YT"
                          value="262"
                        >
                          Mayotte (+262)
                        </option>
                        <option
                          data-countryCode="MX"
                          value="52"
                        >
                          Mexico (+52)
                        </option>
                        <option
                          data-countryCode="FM"
                          value="691"
                        >
                          Micronesia (+691)
                        </option>
                        <option
                          data-countryCode="MD"
                          value="373"
                        >
                          Moldova (+373)
                        </option>
                        <option
                          data-countryCode="MC"
                          value="377"
                        >
                          Monaco (+377)
                        </option>
                        <option
                          data-countryCode="MN"
                          value="976"
                        >
                          Mongolia (+976)
                        </option>
                        <option
                          data-countryCode="ME"
                          value="382"
                        >
                          Montenegro (+382)
                        </option>
                        <option
                          data-countryCode="MS"
                          value="1664"
                        >
                          Montserrat (+1664)
                        </option>
                        <option
                          data-countryCode="MA"
                          value="212"
                        >
                          Morocco (+212)
                        </option>
                        <option
                          data-countryCode="MZ"
                          value="258"
                        >
                          Mozambique (+258)
                        </option>
                        <option
                          data-countryCode="MN"
                          value="95"
                        >
                          Myanmar (+95)
                        </option>
                        <option
                          data-countryCode="NA"
                          value="264"
                        >
                          Namibia (+264)
                        </option>
                        <option
                          data-countryCode="NR"
                          value="674"
                        >
                          Nauru (+674)
                        </option>
                        <option
                          data-countryCode="NP"
                          value="977"
                        >
                          Nepal (+977)
                        </option>
                        <option
                          data-countryCode="NC"
                          value="687"
                        >
                          New Caledonia (+687)
                        </option>
                        <option
                          data-countryCode="NZ"
                          value="64"
                        >
                          New Zealand (+64)
                        </option>
                        <option
                          data-countryCode="NI"
                          value="505"
                        >
                          Nicaragua (+505)
                        </option>
                        <option
                          data-countryCode="NE"
                          value="227"
                        >
                          Niger (+227)
                        </option>
                        <option
                          data-countryCode="NG"
                          value="234"
                        >
                          Nigeria (+234)
                        </option>
                        <option
                          data-countryCode="NU"
                          value="683"
                        >
                          Niue (+683)
                        </option>
                        <option
                          data-countryCode="NF"
                          value="672"
                        >
                          Norfolk Islands (+672)
                        </option>
                        <option
                          data-countryCode="NP"
                          value="1670"
                        >
                          Northern Mariana Islands (+1670)
                        </option>
                        <option
                          data-countryCode="NO"
                          value="47"
                        >
                          Norway (+47)
                        </option>
                        <option
                          data-countryCode="OM"
                          value="968"
                        >
                          Oman (+968)
                        </option>
                        <option
                          data-countryCode="PK"
                          value="92"
                        >
                          Pakistan (+92)
                        </option>
                        <option
                          data-countryCode="PW"
                          value="680"
                        >
                          Palau (+680)
                        </option>
                        <option
                          data-countryCode="PS"
                          value="970"
                        >
                          Palestine (+970)
                        </option>
                        <option
                          data-countryCode="PA"
                          value="507"
                        >
                          Panama (+507)
                        </option>
                        <option
                          data-countryCode="PG"
                          value="675"
                        >
                          Papua New Guinea (+675)
                        </option>
                        <option
                          data-countryCode="PY"
                          value="595"
                        >
                          Paraguay (+595)
                        </option>
                        <option
                          data-countryCode="PE"
                          value="51"
                        >
                          Peru (+51)
                        </option>
                        <option
                          data-countryCode="PH"
                          value="63"
                        >
                          Philippines (+63)
                        </option>
                        <option
                          data-countryCode="PL"
                          value="48"
                        >
                          Poland (+48)
                        </option>
                        <option
                          data-countryCode="PT"
                          value="351"
                        >
                          Portugal (+351)
                        </option>
                        <option
                          data-countryCode="PR"
                          value="1787"
                        >
                          Puerto Rico (+1787)
                        </option>
                        <option
                          data-countryCode="QA"
                          value="974"
                        >
                          Qatar (+974)
                        </option>
                        <option
                          data-countryCode="RE"
                          value="262"
                        >
                          Réunion (+262)
                        </option>
                        <option
                          data-countryCode="RO"
                          value="40"
                        >
                          Romania (+40)
                        </option>
                        <option
                          data-countryCode="RU"
                          value="7"
                        >
                          Russia (+7)
                        </option>
                        <option
                          data-countryCode="RW"
                          value="250"
                        >
                          Rwanda (+250)
                        </option>
                        <option
                          data-countryCode="WS"
                          value="685"
                        >
                          Samoa (+685)
                        </option>
                        <option
                          data-countryCode="SM"
                          value="378"
                        >
                          San Marino (+378)
                        </option>
                        <option
                          data-countryCode="ST"
                          value="239"
                        >
                          São Tomé &amp; Principe (+239)
                        </option>
                        <option
                          data-countryCode="SA"
                          value="966"
                        >
                          Saudi Arabia (+966)
                        </option>
                        <option
                          data-countryCode="SN"
                          value="221"
                        >
                          Senegal (+221)
                        </option>
                        <option
                          data-countryCode="CS"
                          value="381"
                        >
                          Serbia (+381)
                        </option>
                        <option
                          data-countryCode="SC"
                          value="248"
                        >
                          Seychelles (+248)
                        </option>
                        <option
                          data-countryCode="SL"
                          value="232"
                        >
                          Sierra Leone (+232)
                        </option>
                        <option
                          data-countryCode="SG"
                          value="65"
                        >
                          Singapore (+65)
                        </option>
                        <option
                          data-countryCode="SK"
                          value="421"
                        >
                          Slovakia (+421)
                        </option>
                        <option
                          data-countryCode="SI"
                          value="386"
                        >
                          Slovenia (+386)
                        </option>
                        <option
                          data-countryCode="SB"
                          value="677"
                        >
                          Solomon Islands (+677)
                        </option>
                        <option
                          data-countryCode="SO"
                          value="252"
                        >
                          Somalia (+252)
                        </option>
                        <option
                          data-countryCode="ZA"
                          value="27"
                        >
                          South Africa (+27)
                        </option>
                        <option
                          data-countryCode="SS"
                          value="211"
                        >
                          South Sudan (+211)
                        </option>
                        <option
                          data-countryCode="ES"
                          value="34"
                        >
                          Spain (+34)
                        </option>
                        <option
                          data-countryCode="LK"
                          value="94"
                        >
                          Sri Lanka (+94)
                        </option>
                        <option
                          data-countryCode="SH"
                          value="290"
                        >
                          St. Helena (+290)
                        </option>
                        <option
                          data-countryCode="KN"
                          value="1869"
                        >
                          St. Kitts and Nevis (+1869)
                        </option>
                        <option
                          data-countryCode="SC"
                          value="1758"
                        >
                          St. Lucia (+1758)
                        </option>
                        <option
                          data-countryCode="PM"
                          value="508"
                        >
                          St. Pierre and Miquelon (+508)
                        </option>
                        <option
                          data-countryCode="SD"
                          value="249"
                        >
                          Sudan (+249)
                        </option>
                        <option
                          data-countryCode="SR"
                          value="597"
                        >
                          Suriname (+597)
                        </option>
                        <option
                          data-countryCode="SE"
                          value="46"
                        >
                          Sweden (+46)
                        </option>
                        <option
                          data-countryCode="CH"
                          value="41"
                        >
                          Switzerland (+41)
                        </option>
                        <option
                          data-countryCode="SI"
                          value="963"
                        >
                          Syria (+963)
                        </option>
                        <option
                          data-countryCode="TW"
                          value="886"
                        >
                          Taiwan (+886)
                        </option>
                        <option
                          data-countryCode="TJ"
                          value="992"
                        >
                          Tajikstan (+992)
                        </option>
                        <option
                          data-countryCode="TZ"
                          value="255"
                        >
                          Tanzania (+255)
                        </option>
                        <option
                          data-countryCode="TH"
                          value="66"
                        >
                          Thailand (+66)
                        </option>
                        <option
                          data-countryCode="TG"
                          value="228"
                        >
                          Togo (+228)
                        </option>
                        <option
                          data-countryCode="TK"
                          value="690"
                        >
                          Tokelau (+690)
                        </option>
                        <option
                          data-countryCode="TO"
                          value="676"
                        >
                          Tonga (+676)
                        </option>
                        <option
                          data-countryCode="TT"
                          value="1868"
                        >
                          Trinidad &amp; Tobago (+1868)
                        </option>
                        <option
                          data-countryCode="TN"
                          value="216"
                        >
                          Tunisia (+216)
                        </option>
                        <option
                          data-countryCode="TR"
                          value="90"
                        >
                          Turkey (+90)
                        </option>
                        <option
                          data-countryCode="TM"
                          value="993"
                        >
                          Turkmenistan (+993)
                        </option>
                        <option
                          data-countryCode="TC"
                          value="1649"
                        >
                          Turks &amp; Caicos Islands (+1649)
                        </option>
                        <option
                          data-countryCode="TV"
                          value="688"
                        >
                          Tuvalu (+688)
                        </option>
                        <option
                          data-countryCode="UG"
                          value="256"
                        >
                          Uganda (+256)
                        </option>
                        <option
                          data-countryCode="UA"
                          value="380"
                        >
                          Ukraine (+380)
                        </option>
                        <option
                          data-countryCode="AE"
                          value="971"
                        >
                          United Arab Emirates (+971)
                        </option>
                        <option
                          data-countryCode="GB"
                          value="44"
                        >
                          United Kingdom (+44)
                        </option>
                        <option
                          data-countryCode="US"
                          value="1"
                        >
                          United States of America (+1)
                        </option>
                        <option
                          data-countryCode="UY"
                          value="598"
                        >
                          Uruguay (+598)
                        </option>
                        <option
                          data-countryCode="UZ"
                          value="998"
                        >
                          Uzbekistan (+998)
                        </option>
                        <option
                          data-countryCode="VU"
                          value="678"
                        >
                          Vanuatu (+678)
                        </option>
                        <option
                          data-countryCode="VA"
                          value="39"
                        >
                          Vatican City (+39)
                        </option>
                        <option
                          data-countryCode="VE"
                          value="58"
                        >
                          Venezuela (+58)
                        </option>
                        <option
                          data-countryCode="VN"
                          value="84"
                        >
                          Vietnam (+84)
                        </option>
                        <option
                          data-countryCode="VG"
                          value="1284"
                        >
                          Virgin Islands - British (+1284)
                        </option>
                        <option
                          data-countryCode="VI"
                          value="1340"
                        >
                          Virgin Islands - US (+1340)
                        </option>
                        <option
                          data-countryCode="WF"
                          value="681"
                        >
                          Wallis &amp; Futuna (+681)
                        </option>
                        <option
                          data-countryCode="EH"
                          value="967"
                        >
                          Western Sahara (+212)
                        </option>
                        <option
                          data-countryCode="YE"
                          value="967"
                        >
                          Yemen (+967)
                        </option>
                        <option
                          data-countryCode="ZM"
                          value="260"
                        >
                          Zambia (+260)
                        </option>
                        <option
                          data-countryCode="ZW"
                          value="263"
                        >
                          Zimbabwe (+263)
                        </option>
                      </Field>
                    </div>
                  </div>

                  <div class="col-span-3">
                    <label
                      for="customer_phone_number"
                      class="block text-xs"
                      :class="{ 'text-red-500': errors.customer_phone_number && formDirty }"
                    >{{ $t('fields.phonenumber') }} *</label>
                    <div class="mt-1">
                      <Field
                        v-model.trim="form.customer_phone_number"
                        type="number"
                        name="customer_phone_number"
                        class="py-1 px-2 block w-full shadow-sm border rounded-sm focus:outline-none focus:ring-transparent"
                        :class="{ error: errors.customer_phone_number }"
                        @change="validateForm()"
                      />
                    </div>
                  </div>
                </div>

                <!-- customer_notes -->
                <div
                  id="field_customer_notes"
                  :class="{ hidden: transaction.method == 'elockers' }"
                >
                  <label
                    for="customer_notes"
                    class="block text-xs"
                    :class="{ 'text-red-500': errors.customer_notes && formDirty }"
                  >{{ $t('fields.customer_notes') }}</label>
                  <div class="mt-1">
                    <Field
                      v-model="form.customer_notes"
                      as="textarea"
                      name="customer_notes"
                      class="py-1 px-2 block w-full shadow-sm border rounded-sm focus:outline-none focus:ring-transparent"
                      :class="{ error: errors.customer_notes }"
                      @change="validateForm()"
                    />
                  </div>
                </div>
                <div
                  v-if="isEnableOptinMarketing"
                  class="sm:col-span-1 mt-3 mb-3 flex justify-end items-start gap-1 marketing_opt_in_container"
                >
                  <label
                    for="opt_in_marketing"
                    class="block text-xs"
                  >{{ optInMarketingLabel }}</label>
                  <div class="flex items-center">
                    <Field
                      id="opt_in_marketing"
                      v-model="form.opt_in_marketing"
                      type="checkbox"
                      name="opt_in_marketing"
                      :value="true"
                      :unchecked-value="false"
                      class="form-checkbox"
                      :class="{ error: errors.opt_in_marketing }"
                      @change="validateForm()"
                    />
                  </div>
                </div>
              </div>

              <div
                v-if="transaction.account?.id"
                class="mb-3 content-container checkout-box radius-anchor flex items-center justify-between"
              >
                <h2 class="text-2xl">
                  {{ $t('fields.account') }}
                </h2>
                <div class="btn btn-secondary cursor-auto">
                  {{ transaction.account?.name }}
                </div>
              </div>

              <!-- voucher -->
              <!-- <voucher-order :location_slug="location_slug" v-if="transaction.vouchers.length > 0" /> -->

              <!-- method -->
              <div
                v-if="['delivery', 'takeaway'].includes(form.method)"
                class="content-container mt-5 mb-3 checkout-box shadow-anchor radius-anchor"
              >
                <div class="-ml-2 -mt-2 flex flex-wrap items-baseline">
                  <h2 class="ml-2 mt-2 text-2xl font-bold leading-6">
                    {{ $t('order.deliverymethod') }}
                  </h2>
                </div>
                <div class="flex justify-between gap-4">
                  <Field
                    v-model="form.method"
                    type="hidden"
                    name="method"
                    @change="validateForm()"
                  />

                  <!-- delivery -->
                  <div
                    v-if="location.function_delivery == true"
                    class="cursor-pointer flex-grow relative border p-4 flex radius-anchor"
                    :class="form.method == 'delivery' ? 'input-selected' : ''"
                    @click="
                      setFieldValue('method', 'delivery');
                      setMethod('delivery');
                      validateForm();
                    "
                  >
                    <div class="flex items-center h-5">
                      <input
                        id="method_delivery"
                        v-model="form.method"
                        type="radio"
                        :checked="form.method == 'delivery'"
                        name="method"
                        value="delivery"
                        class="focus:outline-none h-4 w-4 cursor-pointer border"
                      >
                    </div>
                    <label
                      for="method_delivery"
                      class="ml-3 flex flex-col cursor-pointer"
                    >
                      <span class="block text-sm font-medium">{{ $t('fields.delivery') }}</span>
                    </label>
                  </div>

                  <!-- tableservice -->
                  <!-- <div  v-if="location.delivery_type == 'tables'" class="cursor-pointer flex-grow relative border p-4 flex rounded-sm" :class="form.method == 'tableservice' ? 'border-highlight' : ''" @click="setFieldValue('method', 'tableservice');setMethod('tableservice')">
                                            <div class="flex items-center h-5">
                                                <input type="radio" id="method_delivery" :checked="form.method == 'tableservice'" v-model="form.method" name="method" value="tableservice" class="focus:outline-none focus:ring-transparent h-4 w-4 text-highlight cursor-pointer border" />
                                            </div>
                                            <label for="method_delivery" class="ml-3 flex flex-col cursor-pointer">
                                                <span class="block text-sm font-medium">Bezorgen</span>
                                            </label>
                                        </div> -->

                  <!-- takeaway -->
                  <div
                    v-if="location.function_takeaway == true"
                    class="cursor-pointer flex-grow relative border p-4 flex radius-anchor"
                    :class="form.method == 'takeaway' ? 'input-selected' : ''"
                    @click="
                      setFieldValue('method', 'takeaway');
                      setMethod('takeaway');
                      validateForm();
                    "
                  >
                    <div class="flex items-center h-5">
                      <input
                        id="method_takeaway"
                        v-model="form.method"
                        type="radio"
                        :checked="form.method == 'takeaway'"
                        name="method"
                        value="takeaway"
                        class="focus:outline-none h-4 w-4 cursor-pointer border"
                      >
                    </div>
                    <label
                      for="method_takeaway"
                      class="ml-3 flex flex-col cursor-pointer"
                    >
                      <span class="block text-sm font-medium">{{ $t('fields.takeaway') }}</span>
                    </label>
                  </div>
                  {{ errors.method }}
                </div>

                <!-- datetime selection -->
                <form-date-time-selection
                  ref="formdatetime"
                  :method="form.method"
                  @changed="setTransactionMethodDateTime($event), validateForm(true)"
                />
                <!-- :date="transaction.menu_date" -->
              </div>

              <form-area-selection
                ref="formarea"
                @changed="setArea($event), validateForm(true)"
              />
              <form-timeslot-selection
                ref="formtimeslot"
                :area_id="form.area_id"
                @changed="setTransactionMethodDateTime($event), validateForm(true)"
              />

              <!-- delivery address -->
              <div
                v-if="['delivery'].includes(form.method)"
                class="content-container mt-5 checkout-box shadow-anchor radius-anchor"
              >
                <div class="mb-3">
                  <div class="-ml-2 -mt-2 flex flex-wrap items-baseline">
                    <h3 class="ml-2 mt-2 text-2xl font-bold leading-6">
                      {{ $t('order.location') }}
                    </h3>
                  </div>
                </div>

                <!-- zipcode & housenumber -->
                <div v-if="location.delivery.type == 'zipcode'">
                  <form-address
                    ref="formaddress"
                    :address="transaction.delivery_address"
                    @changed="setCustomerAddress($event), validateForm(true)"
                  />
                </div>

                <div v-if="location.delivery.type == 'tables'">
                  <label
                    for="table_id"
                    class="block text-xs"
                    :class="{ 'text-red-500': errors.table_id && formDirty }"
                  >{{ $t('fields.table') }} *</label>
                  <div class="mt-1">
                    <Field
                      v-model="form.table_id"
                      as="select"
                      name="table_id"
                      class="py-1 px-2 block w-full shadow-sm border rounded-sm focus:outline-none focus:ring-transparent"
                      :class="{ error: errors.table_id }"
                      @change="validateForm()"
                    >
                      <option />
                      <option
                        v-for="(table, index) in tables"
                        :key="index"
                        :value="table.id"
                        :selected="form.table_id == table.id"
                      >
                        {{ table.attributes.name }}
                      </option>
                    </Field>
                  </div>
                </div>
              </div>

              <div
                class="my-5 space-y-3"
              >
                <div class="flex items-start">
                  <div class="absolute flex items-center h-5">
                    <Field
                      id="termsandconditions"
                      v-model="form.termsandconditions"
                      type="checkbox"
                      name="termsandconditions"
                      :value="true"
                      class="form-checkbox"
                      :class="{ error: errors.termsandconditions }"
                      @change="validateForm()"
                    />
                  </div>
                  <div class="pl-7 text-sm leading-5">
                    <label
                      for="termsandconditions"
                      class="text-base text-main"
                      :class="{ 'text-error': errors.termsandconditions }"
                    >{{ $t('fields.termsandconditions') }}</label>
                    <!-- <modal-terms-and-conditions /> -->
                    <div class="">
                      <a

                        v-if="config.legals['terms-of-use']"
                        href="#"
                        @click.prevent="openPopup($t('termsandconditions'),config.legals['terms-of-use'])"

                      >
                        {{ $t('modal.termsandcondition.button.open') }}
                      </a>
                    </div>
                  </div>
                </div>

                <div class="flex items-start">
                  <div class="absolute flex items-center h-5">
                    <Field
                      id="privacypolicy"
                      v-model="form.privacypolicy"
                      type="checkbox"
                      name="privacypolicy"
                      :value="true"
                      class="form-checkbox"
                      :class="{ error: errors.privacypolicy }"
                      @change="validateForm()"
                    />
                  </div>
                  <div class="pl-7 text-sm leading-5">
                    <label
                      for="privacypolicy"
                      class="text-base text-main"
                      :class="{ 'text-error': errors.privacypolicy }"
                    >{{
                      $t('fields.privacypolicy')
                    }}</label>
                    <div class="underline">
                      <a

                        v-if="config.legals['privacy-policy']"
                        href="#"
                        @click.prevent="openPopup($t('privacypolicy'),config.legals['privacy-policy'])"

                      >
                        {{ $t('modal.privacypolicy.button.open') }}
                      </a>
                    </div>
                    <!-- <modal-privacy-policy /> -->
                  </div>
                </div>
              </div>

              <!-- <div class="proceed-to-checkout-button-wrapper">
                                <button
                                    class="w-full inline-flex justify-center mt-5 items-center px-6 py-3 btn btn-primary text-base leading-6 font-bold rounded-sm focus:outline-none transition ease-in-out duration-150"
                                    :class="{ disabled: !formValid.valid }"
                                    @click="(direction = 'pages-forward'), (step = 'paymentStep')"
                                >
                                    Naar betaalmethoden
                                </button>
                            </div> -->
            </div>
            <div v-show="step == 'paymentStep'">
              <paymenttypes
                ref="paymenttypes"
                :amount="amount"
                :form-valid="formValid"
                :disabled="saving"
                @selected="setPaymenttype($event)"
                @paymenttypes-loaded="handlePaymenttypesLoaded"
              />
            </div>
          </transition-group>

          <!-- paymenttypes -->
          <div class="mt-5 mb-10 relative proceed-to-checkout-button-wrapper">
            <template v-if="form.method == 'tableservice' && table.attributes.paymenttype == 'onaccount'">
              <button
                :disabled="location.delivery.minimum > transaction.subtotal || saving || transaction.lines.length === 0"
                type="submit"
                class="w-full inline-flex justify-center items-center px-6 py-3 btn btn-primary text-base leading-6 font-bold rounded-sm focus:outline-none transition ease-in-out duration-150"
                @click="formDirty = true"
              >
                {{ $t('order.button.confirm') }}
                <svg
                  v-if="saving"
                  class="animate-spin ml-3 h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    class="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    stroke-width="4"
                  />
                  <path
                    class="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  />
                </svg>
                <svg
                  v-else
                  class="ml-3 -mr-1 h-5 w-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10.2929 3.29289C10.6834 2.90237 11.3166 2.90237 11.7071 3.29289L17.7071 9.29289C18.0976 9.68342 18.0976 10.3166 17.7071 10.7071L11.7071 16.7071C11.3166 17.0976 10.6834 17.0976 10.2929 16.7071C9.90237 16.3166 9.90237 15.6834 10.2929 15.2929L14.5858 11L3 11C2.44772 11 2 10.5523 2 10C2 9.44772 2.44772 9 3 9H14.5858L10.2929 4.70711C9.90237 4.31658 9.90237 3.68342 10.2929 3.29289Z"
                    clip-rule="evenodd"
                  />
                </svg>

                <span
                  v-if="transaction.total"
                  class="inline-total inline-block ml-4"
                >{{ $n(transaction.total, currency) }}</span>
              </button>
            </template>

            <template v-else>
              <button
                v-if="step == 'personalInfoStep'"
                class="w-full inline-flex justify-center mt-5 items-center px-6 py-3 btn btn-primary text-base leading-6 font-bold rounded-sm focus:outline-none transition ease-in-out duration-150"
                :class="{ disabled: !firstPageValidatedAdditionalFields || !formValid.valid }"
                @click="(direction = 'pages-forward'), (setStep('paymentStep')), step_counter++"
              >
                {{ fixedPaymentMethod ? ( $t('order.button.confirm') + ' ' + $n(transaction.total, currency)) : $t('order.button.to_payment_methods') }}
              </button>
              <div v-else-if="step == 'paymentStep'">
                <!-- applepay -->
                <div
                  v-if="paymenttype.attributes?.psp_method === 'applepay'"
                  id="paymenttype_applepay"
                  class="adyenpayment"
                />

                <!-- default button -->
                <div v-else>
                  <button
                    :disabled="location.delivery.minimum > transaction.subtotal || saving || transaction.lines.length === 0"
                    type="submit"
                    class="w-full inline-flex justify-center items-center px-6 py-3 btn btn-primary text-base leading-6 font-bold rounded-sm focus:outline-none transition ease-in-out duration-150"
                    @click="formDirty = true"
                  >
                    {{ $t('order.button.confirm') }}
                    <svg
                      v-if="saving"
                      class="animate-spin ml-3 h-5 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        class="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        stroke-width="4"
                      />
                      <path
                        class="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      />
                    </svg>
                    <svg
                      v-else
                      class="ml-3 -mr-1 h-5 w-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10.2929 3.29289C10.6834 2.90237 11.3166 2.90237 11.7071 3.29289L17.7071 9.29289C18.0976 9.68342 18.0976 10.3166 17.7071 10.7071L11.7071 16.7071C11.3166 17.0976 10.6834 17.0976 10.2929 16.7071C9.90237 16.3166 9.90237 15.6834 10.2929 15.2929L14.5858 11L3 11C2.44772 11 2 10.5523 2 10C2 9.44772 2.44772 9 3 9H14.5858L10.2929 4.70711C9.90237 4.31658 9.90237 3.68342 10.2929 3.29289Z"
                        clip-rule="evenodd"
                      />
                    </svg>

                    <span
                      v-if="transaction.total"
                      class="inline-total inline-block ml-4"
                    >{{
                      $n(transaction.total, currency)
                    }}</span>
                  </button>
                </div>
              </div>
            </template>
          </div>
        </div>
        <basket
          :edit="false"
          :tip="true"
          class="flex-grow hidden md:block"
        />
      </div>
    </Form>
  </div>
  <modal-items-not-available ref="itemsnotavailable" />
  <ModalPopup ref="popup" />
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import { Field, Form } from 'vee-validate';

// import db from "../../lib/db";
import * as _log from '../../lib/log';
import * as _api from '../../lib/api';
import * as _notification from '../../lib/notification';
import * as _state from '../../lib/state';
import * as _table from '../../lib/table';
import * as _payment from '../../lib/payment';
import * as _transaction from '../../lib/transaction';
import * as _applicationState from '../../lib/application-state';

// mixins
import CheckoutHelper from '../../mixins/checkout.helper.js';
import CheckoutChecksHelper from '../../mixins/checkout-checks.helper.js';
import CheckoutValidationHelper from '../../mixins/checkout-validation.helper.js';

// import VoucherOrder from '../../components/VoucherOrder';
import FormDateTimeSelection from '../../components/FormDateTimeSelection';
import FormAreaSelection from '../../components/FormAreaSelection';
import FormAddress from '../../components/FormAddress';
import FormTimeslotSelection from '../../components/FormTimeslotSelection';
import ModalItemsNotAvailable from '../../components/ModalItemsNotAvailable';
import Paymenttypes from '../../components/Paymenttypes';
import Basket from '../../components/Basket';
import ModalTermsAndConditions from '../../components/ModalTermsAndConditions';
import ModalPrivacyPolicy from '../../components/ModalPrivacyPolicy';
import ModalPopup from '@/components/ModalPopup.vue';

// set the logger
const logger = _log.get('CHECKOUT');

export default {
    name: 'CheckoutTransaction',
    components: {
        Field,
        Form,
        FormAddress,
        FormAreaSelection,
        FormDateTimeSelection,
        FormTimeslotSelection,
        Paymenttypes,
        ModalItemsNotAvailable,
        Basket,
        ModalPopup,
        // ModalTermsAndConditions,
        // ModalPrivacyPolicy,
    },
    mixins: [CheckoutHelper, CheckoutChecksHelper, CheckoutValidationHelper],
    props: ['location_slug'],
    data() {
        let validationRules = this.getValidationRules();

        return {
            loading: true,
            saving: false,
            formValid: false,
            formDirty: false,
            basket_link: '',
            step: 'personalInfoStep',
            firstPageValidatedAdditionalFields: false,
            diretion: 'pages-forward',

            fixedPaymentMethod: false,
            paymenttypes: [],
            validationRules,
            form: {},
            error_messages: [],
            step_counter: 5,
            accounts: [],
            tables: [],
            // timeslots: [],
            paymenttype: false,

            // unavailable_dates: [],
            // maximum_date: false,
            // timeslot_view: 'inputfield',

            // used
            zipcode_available: true,

            // datepicker_attributes: [{
            //     excludeDates: null
            // }],
        };
    },
    computed: {
        location() {
            return _state.get('location/getLocation');
        },

        config() {
            return _state.get('config/getConfig');
        },

        table() {
            return _table.get();
        },

        route_from() {
            return _state.get('applicationState/getApplicationState')['routeFrom'];
        },

        transaction() {
            return _state.get('transaction/getTransaction');
        },

        selectedTip() {
            return _transaction.getTip();
        },

        emailOnlyForm() {
            if (this.form.method !== 'takeaway') {
                return false;
            }
            return this.location.takeaway.emailonly == true;
        },

        user() {
            return _state.get('user/getUser');
        },

        paymenttypesAvailable() {
            if (this.transaction.lines.length === 0) {
                return false;
            }

            return (
                ['delivery', 'takeaway', 'quickorder', 'elockers', 'reservation'].includes(this.form.method) ||
                (this.form.method == 'tableservice' && this.table.attributes.paymenttype == 'direct')
            );
        },

        amount() {
            return this.transaction.total_unpaid;
        },

        getAccount() {
            return _transaction.getAccount();
        },

        currency() {
            return this.config.settings.currency;
        },

        applicationLockerFlowStep() {
            return _applicationState.getLockerFlowStep();
        },

        applicationLockerFlowTotalSteps() {
            return _applicationState.getLockerFlowTotalSteps();
        },

        optInMarketingLabel() {
            return this.config.settings.webshop_opt_in_marketing_label;
        },

        isEnableOptinMarketing() {
            return this.config.settings.webshop_enable_opt_in_marketing;
        }
    },

    async mounted() {
        window.checkout = this;

        this.basket_link = this.route_from;

        // location slug
        axios.defaults.headers.common['Location'] = this.location_slug;

        this.step_counter = this.applicationLockerFlowStep;

        // fill the form
        this.form = {
            paymenttype_id: '',
            account_id: this.transaction.account ? this.transaction.account.id : '',
            table_id: this.table ? this.table.id : this.transaction.table ? this.transaction.table.id : '',
            method: this.transaction.method,
            // method_date: '',
            // method_time: '',
            // tip: '',
            // todo: customer_name
            customer_firstname: '',
            customer_lastname: '',
            customer_email: this.user ? this.user.username : '',
            customer_phone_number_countrycode: 'NL',
            customer_phone_number: '',
            customer_notes: '',
            // customer_zipcode: this.transaction.delivery_address ? this.transaction.delivery_address.zipcode : '',
            // customer_housenumber: this.transaction.delivery_address ? this.transaction.delivery_address.housenumber : '',
            // customer_streetname: this.transaction.delivery_address ? this.transaction.delivery_address.streetname : '',
            // customer_city: this.transaction.delivery_address ? this.transaction.delivery_address.city : '',
            opt_in_marketing: false,
        };

        const member = _transaction.getHooliMember();

        if (member && member.hooliMemberEmail) {
            this.form.customer_email = member.hooliMemberEmail;
        }

        if (this.route_from === false) {
            this.route_from = '';
        }

        // load all data
        await this.initialize();
    },

    methods: {
        handlePaymenttypesLoaded(data) {
          this.paymenttypes = data;
          if (this.paymenttypes?.length === 1 && this.paymenttypes[0].attributes.psp_type === 'sjefpay') {
              this.fixedPaymentMethod = true;
          }
        },

        async initialize() {
            // todo: create formtable component
            if (['delivery'].includes(this.form.method) && this.location.delivery.type === 'tables') {
                this.tables = await _api.get('locations/:location_slug/tables', this.location.slug);
            }

            // todo: check on mount??
            await this.checkItemsAvailability();

            this.loading = false;

            this.validateForm(true);
        },

        async validateForm(prestine) {
            let formAreaValid = true;
            let formDateTimeValid = true;
            let formTimeslotValid = true;
            let formAddressValid = true;
            let validation = await this.$refs.form.validate();

            this.formValid = validation;

            if (!prestine) {
                this.formDirty = true;
            }

            if (this.form.method == 'delivery' || this.form.method == 'takeaway') {
                formDateTimeValid = await this.$refs.formdatetime.validate();
            }

            if (this.form.method == 'delivery') {
                formAddressValid = await this.$refs.formaddress.validate();
            }

            if (this.form.method == 'quickorder' && this.location.quickorder.areaselection == true) {
                formAreaValid = await this.$refs.formarea.validate();
            }

            this.firstPageValidatedAdditionalFields =
                formAreaValid == true && formDateTimeValid == true && formAddressValid == true && formTimeslotValid == true;

            return this.formValid;
        },

        async onSubmit(data = {}) {
            if (this.saving === true) {
                return;
            }

            if (this.table ? this.table.attributes.plug_and_play : false) {
                const response = await _api.post('table/set-customer-info', {
                    table_id: this.table.id,
                    customer_info: this.form,
                });
            }

            this.saving = true;

            // todo: vouchers
            // if (await this.reserveVouchers() === false) {
            //     this.saving = false;
            //     return;
            // }

            if ((await this.validateForm()) == false) {
                return this.endSubmit();
            }

            if ((await this.canSubmit()) === false) {
                this.saving = false;
                return;
            }

            if (this.form.method == 'tableservice' && this.table.attributes.paymenttype == 'onaccount') {
                await this.createTableTransaction(data);

                return this.endSubmit();
            }

            // validate area selection
            if (this.form.method == 'quickorder' && this.location.quickorder.areaselection == true) {
                if ((await this.$refs.formarea.validate()) === false) {
                    return this.endSubmit();
                }
            }

            if (!data.paymenttype_valid) {
                if (this.$refs.paymenttypes.validate() === false) {
                    return this.endSubmit();
                }
            }

            // create the transaction
            await this.createTransaction();

            return true;
        },

        setTransactionMethodDateTime(event) {
            _transaction.setMethodDateTime(moment(event.date).format('YYYY-MM-DD'), event.time);
        },

        setArea(event) {
            this.form.area_id = event.area_id;

            this.$refs.formtimeslot.load(event.area_id);
        },

        setTimeslot(event) {
            this.form.area_id = event.area_id;
        },

        setCustomerAddress(address) {
            this.form.customer_zipcode = address.zipcode;
            this.form.customer_housenumber = address.housenumber;
            this.form.customer_streetname = address.streetname;
            this.form.customer_city = address.city;
        },

        async createTransaction() {
            _transaction.setArea({ id: this.form.area_id });
            _transaction.setMethod(this.form.method, this.location.quickorder.mode);
            _transaction.setAccount({
                id: this.form.account_id,
            });
            _transaction.setCustomer({
                firstname: this.form.customer_firstname,
                lastname: this.form.customer_lastname,
                email: this.form.customer_email,
                phone_number: this.form.customer_phone_number,
                zipcode: this.form.customer_zipcode,
                housenumber: this.form.customer_housenumber,
                streetname: this.form.customer_streetname,
                city: this.form.customer_city,
                notes: this.form.customer_notes,
            });

            _transaction.setOptInMarketing(this.form.opt_in_marketing);

            if ((await _transaction.send()) == false) {
                // todo: translate
                _notification.set('notification.transaction.error', 'error');
                return;
            }

            logger.log('success', this.transaction)();

            this.makePayment();
        },

        async makePayment() {
            // make payment
            const response = await _payment.send(this.amount);

            logger.log('payment response', response)();

            if (response && response.result != 'success') {
                // todo: translate
                _notification.set('notification.payment.error', 'error');
                return;
            }

            _payment.handleResultCode(this.location_slug, response.data);
        },

        async createTableTransaction(data) {
            // set table
            data.table_id = this.table.id;

            if ((await _transaction.sendToTable()) == false) {
                // todo: translate
                _notification.set('notification.transaction.error', 'error');
                return;
            }

            // redirect to payment
            this.$router.push({
                name: 'checkout.table',
                params: {
                    location_slug: this.location_slug,
                    ordered: true,
                },
            });
        },

        // set method: delivery or takeaway
        setMethod(method) {
            _transaction.setMethod(method);

            // todo: is this needed?
            this.form.method = method;
        },

        setPaymenttype(paymenttype) {
            this.paymenttype = paymenttype;
            this.form.paymenttype_id = paymenttype.id;
        },

        setStep(step) {

            // todo. Label van button naar PAY €300,00
            if (this.paymenttypes.length === 1 && this.paymenttypes[0].attributes.psp_type === 'sjefpay') {
              this.setPaymenttype(this.paymenttypes[0]);
              this.onSubmit();
              return;
            }


            this.step = step;
        },

        scrollToTop() {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            // window.scrollTo(0,0);
        },

        redirectToMenu() {
            const routes = {
                quickorder: 'quickorder',
                takeaway: 'afhalen',
                delivery: 'bezorgen',
                tableservice: 'tafel',
                // todo: lockers
            };

            if (routes[this.form.method]) {
                this.$router.push('/' + this.location.slug + '/' + routes[this.form.method]);
            }

            return this.$router.push({ name: 'landing' });
        },

        openPopup(title, content) {
            this.$refs.popup.open(title, content);
        },

        // todo
        // async reserveVouchers()
        // {
        //     let vouchers = []
        //     let valid = true;

        // 	if (this.transaction.vouchers.length > 0) {

        // 		// set the paid amount equal to total of transaction
        // 		let payable_amount = this.transaction.total;

        //         // loop through the vouchers
        //         for (let voucher of this.transaction.vouchers) {
        // 			// payable_amount = payable_amount - payable_amount_voucher;
        // 			let payable_amount_voucher = 0;

        // 			// when amount of voucher is sufficient for total amount
        // 			if (voucher.amount >= payable_amount) {
        // 				payable_amount_voucher = payable_amount;
        // 			}
        // 			// partial payment (transaction total exceeds voucher amount)
        // 			else {
        // 				payable_amount_voucher = voucher.amount;
        // 			}

        // 			// set the new payable amount
        // 			payable_amount = payable_amount - payable_amount_voucher;

        //             if (payable_amount_voucher > 0) {
        //                 // reserve amount in the voucher
        //                 // todo: needs testing
        //                 const voucherReserve_data = await _api.post('voucher/amount/reserve', {
        //                         vouchercode: voucher.vouchercode,
        //                         amount: payable_amount_voucher,
        //                     });

        //                 if (voucherReserve_data) {
        //                     vouchers.push({
        //                         mutation: voucherReserve_data.mutation,
        //                         amount: payable_amount_voucher,
        //                         vouchercode: voucher.vouchercode,
        //                     })
        //                 } else {
        //                     this.valid = false;
        //                     this.saving = false
        //                     this.error_messages.push({
        //                         message: this.$t('order.voucher.error') + ': ' + voucher.vouchercode,
        //                     })
        //                 }
        //                 _transaction.setVouchers(vouchers);
        //             }
        // 		}
        // 	}
        //     return valid
        // },
    },
};
</script>

<style scoped>
.marketing_opt_in_container label {
    white-space: wrap;
}
</style>
